// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_2YzrV{display:flex;flex-direction:column;overflow:hidden}.delete_DptcX:hover{transform:scale(1.1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_2YzrV",
	"delete": "delete_DptcX"
};
module.exports = ___CSS_LOADER_EXPORT___;
