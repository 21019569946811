// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_9noak{display:flex;flex-direction:column;height:100%;justify-content:space-between}.filters_fLx7p{align-items:flex-end;display:flex;gap:1rem}.sticky_YnzZj{bottom:1rem;display:flex;flex-direction:row;margin:0 auto;min-width:20rem;position:sticky;width:-moz-fit-content;width:fit-content;z-index:1}.filterMenu_NI7y5{display:flex;flex-direction:column}.headerRight_2iPMl{flex:1}.filterToggleItem_kLm2u{cursor:pointer;padding:.5rem}.filterToggleItem_kLm2u:hover{background-color:rgba(0,0,0,.2)}.flexCell_dxzMu{display:flex;gap:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_9noak",
	"filters": "filters_fLx7p",
	"sticky": "sticky_YnzZj",
	"filterMenu": "filterMenu_NI7y5",
	"headerRight": "headerRight_2iPMl",
	"filterToggleItem": "filterToggleItem_kLm2u",
	"flexCell": "flexCell_dxzMu"
};
module.exports = ___CSS_LOADER_EXPORT___;
